import { BrowserModule } from '@angular/platform-browser';
import { TagInputModule } from 'ngx-chips';
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RefreshComponent } from './refresh/refresh.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UrlSerializerService } from './url-serializer.service';
import { UrlSegment, UrlSerializer} from '@angular/router';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticatedHttpService } from './authenticated-http.service';
import { SafePipe }   from './shared/pipes/safe.pipe';
import { SharedPipesModule } from './shared-pipes.module';
import { WebcamModule } from 'ngx-webcam';
import { SignaturePadModule } from 'angular2-signaturepad';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { CandidateDetailsComponent } from './candidate-details/candidate-details.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATETIME_FORMATS, MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import {MatNativeDateModule} from '@angular/material/core';

@NgModule({
	declarations: [
		AppComponent,
		RefreshComponent,
		SafePipe,
		CandidateDetailsComponent
	],
	imports: [
		CommonModule,
		TagInputModule,
		ReactiveFormsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgSelectModule,
		FormsModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		NgbModule,
		NgbPaginationModule,
		NgbAlertModule,
		NgxFileDropModule,
		SharedPipesModule,
		WebcamModule,
		SignaturePadModule,
		MatDatepickerModule,
		MatNativeDateModule
		
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{
			provide: UrlSerializer,
			useClass: UrlSerializerService
		},
		AsyncPipe,
		{
			provide: HTTP_INTERCEPTORS, 
			useClass: AuthenticatedHttpService, 
			multi: true
		},
		/* { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{
			provide: MAT_DATETIME_FORMATS,
			useValue: {
				parse: {},
				display: {
					dateInput: {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					},
					monthInput: {
						month: 'long',
					},
					datetimeInput: {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
					},
					timeInput: {
						hour: '2-digit',
						minute: '2-digit',
					},
					monthYearLabel: {
						year: 'numeric',
						month: 'short',
					},
					dateA11yLabel: {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					},
					monthYearA11yLabel: {
						year: 'numeric',
						month: 'long',
					},
					popupHeaderDateLabel: {
						weekday: 'short',
						month: 'short',
						day: '2-digit',
					},
				},
			}
		}, */
		MatNativeDatetimeModule
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);