import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'folderfilter',
	pure: false
})
export class FolderFilterPipe implements PipeTransform {
	transform(items: any[], filter: String): any {
		if (!items || filter=="") {
			return items;
		}
		return items.filter(item => item.folder_name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1);
	}
}