import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthenticatedHttpService implements HttpInterceptor{

	constructor(private router: Router) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe( tap(() => {},
			(err: any) => {
				if (err instanceof HttpErrorResponse) {
					if (err.status !== 401) {
						return;
					}
				}
			}));
	}
}
