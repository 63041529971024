import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject, concat } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	baseURL = environment.API_GENERAL_URL;

	constructor(private httpClient: HttpClient) { }

	public countryCodes() {
		var headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get("./assets/files/countryCodes.json", { headers: headers });
	}

	public getAllNationality() {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allNationality", { headers: headers });
	}

	public saveCandidateDetails(data: any) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/SaveCandidateDetails", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public uploadCandidatePictureOpen(data: any) {
		let headers = new HttpHeaders();
		return this.httpClient.post(this.baseURL + "api/UploadCandidateImage", data, { headers: headers });
	}

	public uploadCandidateSignOpen(data: any) {
		let headers = new HttpHeaders();
		return this.httpClient.post(this.baseURL + "api/UploadCandidateSign", data, { headers: headers });
	}
}

export interface HttpParamsOptions {
	fromString?: string;
	fromObject?: {
		[param: string]: string | string[];
	};
	encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
	encodeKey(key: string): string
	encodeValue(value: string): string
	decodeKey(key: string): string
	decodeValue(value: string): string
}
